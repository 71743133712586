/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Tanuj's Portfolio",
  description:
    "A passionate individual who always thrive to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Tanuj Chauhan's Portfolio",
    type: "website",
    url: "https://www.tanujchauhan.com/",
  },
};

//Home Page
const greeting = {
  title: "Tanuj Chauhan",
  logo_name: "Tanuj Chauhan",
  nickname: "tanuj_chauhan",
  subTitle:
    "A passionate individual who always thrive to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://docs.google.com/document/d/e/2PACX-1vQuYMJOBm356Lc5Cm5ItOr4iRTFZLzO4a2wCZ2DP2nGhu63CuyLaxRTvQckpN077S26KqKQ_T2pnjxC/pub",
  portfolio_repository: "https://github.com/tanujchauhan76/masterPortfolio",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/tanujchauha76",
  // linkedin: "https://www.linkedin.com/in/tanujchauhan2025/",
  // gmail: "tanujchauhan76@gmail.com",
  // gitlab: "https://gitlab.com/tanujchauhan76,
  // facebook: "https://www.facebook.com/tanujchauhan76/",
  // twitter: "https://twitter.com/tanujchauhan76",
  // instagram: "https://www.instagram.com/tanuj_chauhan/"

  {
    name: "Github",
    link: "https://github.com/tanujchauhan76",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/tanujchauhan2025/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:tanujchauhan76@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/tanujchauhan76",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/people/Tanuj-Chauhan/100004120544345",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/tanuj_chauhan/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive website front end using React, Angular",
        "⚡ Developing mobile applications using React Native and solo android apps using Java",
        "⚡ Creating application backend in Java(Sprint-Boot), PHP(Laravel),"
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26"
          }
          },
          {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6"
          }
          },
          {
          skillName: "Bootstrap",
          fontAwesomeClassname: "simple-icons:bootstrap",
          style: {
            color: "#663399"
          }
          },
          {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699"
          }
          },
          {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E"
          }
          },
          {
          skillName: "Jquery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            backgroundColor: "#fff",
            color: "#0765A8"
          }
          },
          {
          skillName: "React-Native",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#029FCE"
          }
          },
          {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84"
          }
          },
          {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820"
          }
          },
          {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#8993be"
          }
          },
          {
          skillName: "Visual Basic",
          fontAwesomeClassname: "simple-icons:dot-net",
          style: {
            color: "#029FCE"
          }
          },
          {
          skillName: "Sprint-Boot",
          fontAwesomeClassname: "simple-icons:spring",
          style: {
            color: "#71B544"
          }
          },
          {
          skillName: "Laravel",
          fontAwesomeClassname: "simple-icons:laravel",
          style: {
            color: "#FF2C1F"
          }
          },
          {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1"
          }
          },
          {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297"
          }
          },
          {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22"
          }
          },
          {
          skillName: "NGINX",
          fontAwesomeClassname: "simple-icons:nginx",
          style: {
            color: "#009237"
          }
          },
          {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32"
          }
          },
          {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB"
          }
          },
          {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933"
          }
          },
          {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837"
          }
          },
          {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB"
          }
          },
          {
            skillName: "Angular",
            fontAwesomeClassname: "simple-icons:angular",
            style: {
              color: "#dd1b16"
            }
            },
            {
            skillName: "AngularJs",
            fontAwesomeClassname: "simple-icons:angularjs",
            style: {
              color: "#dd1b16"
            }
            },
      
          {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B"
          }
          }
      ]
      },
      {
        title: "Cloud Infra-Architecture",
        fileName: "CloudInfraImg",
        skills: [
          "⚡ Experience working on multiple cloud platforms",
          "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
          "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS"
        ],
        softwareSkills: [
          {
            skillName: "GCP",
            fontAwesomeClassname: "simple-icons:googlecloud",
            style: {
              color: "#4285F4"
            }
          },
          {
            skillName: "AWS",
            fontAwesomeClassname: "simple-icons:amazonaws",
            style: {
              color: "#FF9900"
            }
          },
          {
            skillName: "Azure",
            fontAwesomeClassname: "simple-icons:microsoftazure",
            style: {
              color: "#0089D6"
            }
          },
          {
            skillName: "Firebase",
            fontAwesomeClassname: "simple-icons:firebase",
            style: {
              color: "#FFCA28"
            }
          },
          {
            skillName: "PostgreSQL",
            fontAwesomeClassname: "simple-icons:postgresql",
            style: {
              color: "#336791"
            }
          },
          {
            skillName: "MongoDB",
            fontAwesomeClassname: "simple-icons:mongodb",
            style: {
              color: "#47A248"
            }
          },
          {
            skillName: "Docker",
            fontAwesomeClassname: "simple-icons:docker",
            style: {
              color: "#1488C6"
            }
          },
          {
            skillName: "Kubernetes",
            fontAwesomeClassname: "simple-icons:kubernetes",
            style: {
              color: "#326CE5"
            }
          }
        ]
      },
      {
        title: "UI/UX Design",
        fileName: "DesignImg",
        skills: [
          "⚡ Designing highly attractive user interface for mobile and web applications",
          "⚡ Creating the flow of application functionalities to optimize user experience"
        ],
        softwareSkills: [
          {
            skillName: "Adobe XD",
            fontAwesomeClassname: "simple-icons:adobexd",
            style: {
              color: "#FF2BC2"
            }
          },
          {
            skillName: "Figma",
            fontAwesomeClassname: "simple-icons:figma",
            style: {
              color: "#F24E1E"
            }
          },
          {
            skillName: "Adobe Illustrator",
            fontAwesomeClassname: "simple-icons:adobeillustrator",
            style: {
              color: "#FF7C00"
            }
          },
          {
            skillName: "Inkscape",
            fontAwesomeClassname: "simple-icons:inkscape",
            style: {
              color: "#000000"
            }
          }
        ]
    },

  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
  
  ],
};

const degrees = {
  degrees: [
    {
      title: "Herzing College Montréal, QC, Canada",
      subtitle: "Post-Graduation Diploma Programmer Analyst",
      logo_path: "herzingcollege_logo.png",
      alt_name: "Herzing College Montreal,QC",
      duration: "2019 - 2020",
      descriptions: [
        "⚡ I have learned to write, test, and modify computer code for a variety of computer and software applications.",
        "⚡ Moreover, I have the knowledge, skills, in the modern programming languages and applications required in a software development environment. Areas of study included Microsoft VB.Net applications, MVC pattern, Object-oriented programming with JAVA, database design and development with SQL Server, Internet programming with JavaScript, PHP and android technologies while following agile practices and approach.",
        "⚡ Apart from this, I have done courses on SEO, AWS Cloud Computing, and Full Stack Development.",
      ],
      website_link: "https://www.herzing.ca/locations/montreal",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: " Intro To Cloud Computing",
      subtitle: "- Udemy",
      logo_path: "udemy_logo.png",
      certificate_link:
        "https://www.udemy.com/",
      alt_name: "Udemy ",
      color_code: "#3C3B37",
    },
    {
      title: "Web Development",
      subtitle: "- Herzing College",
      logo_path: "herzingcollege_logo.png",
      certificate_link:
        "https://www.coursera.org/",
      alt_name: "web development",
      color_code: "#01426AF2",
    },
    {
      title: "ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "AWS Certified Developer Associate",
      subtitle: "- Coursera",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/",
      alt_name: "Coursera",
      color_code: "rgb(0 28 85)",
    },
   
   
   
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I am working remotely with Innovance College as a Full-Stack Developer building Mobile, Web Apps and Backend.Besides, that I also have experience working closing with chief of agile practices and DevOps.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Full-Stack Developer",
          company: "Innovance College, Montreal, QC",
          company_url: "http://www.collegeinnovance.com/",
          logo_path: "collegeinnovance_logo.jpg",
          duration: "December 2020 - PRESENT",
          location: "Montreal, Quebec",
          description:
            "Working as a Full-Stack Developer including Mobile, Web Apps and Backend with Innovance College, Montreal.",
          color: "#0879bf",
        },
        {
          title: "Web App Developer",
          company: "Freelancer, Remote",
          company_url: "https://www.freelancer.com/",
          logo_path: "freelancer-icon.svg",
          duration: "January 2019 - PRESENT",
          location: "Remote",
          description:
            "Experienced working as a freelancer in UX/UI and web application development.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Full Stack Web Developer",
          company: "Innovance College, Montreal, QC",
          company_url: "http://www.collegeinnovance.com/",
          logo_path: "collegeinnovance_logo.png",
          duration: "August 2020 - December 2020",
          location: "Montréal, Quebec",
          description:
            "It was 4 months internship, and my duties were to work on a CMS project. The Project's two objectives: (1) To build a Content Management System while implementing MVC Object-oriented programming approach with integrating MySQL database application architecture. (2) Designing user interactions on web pages. And the technologies that I used were: Bootstrap,HTML5,CSS3, JavaScript, JQuery for the front-end and PHP for the backend. The project was deployed on AWS Platform.",
          color: "#ee3c26",
        },

      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups.",
          color: "#D83B01",
        },    
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create websites, applications and deploy them using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "tanujchauhan.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with both frontent and backend, PHP, React, Android, Cloud and Web Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "wwww.tanujchauhan.com",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "9909 Bellamy Hill NW, Edmonton, T5K 2B1, Alberta",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/UKESpRoWe3mFVZ1g7",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+1(438) 833 8975",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
